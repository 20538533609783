
<template>
  <div class="Awarp">
    <dashCard
      class="lvscBox18"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('lvscBox22_statusBI','配电设备BI状态', getZEdata)}}</template>
      <template slot="aside">
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px"
          @change="handleChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in eqlist"
            :key="key"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </template>
      <template slot="operate"></template>
      <div class="list-content">
        <a-table
          ref="aTable"
          :columns="columns"
          :data-source="detailInfo.list"
          :pagination="false"
          :scroll="{ y: tableHeight }"
          rowKey="id"
        >
        </a-table>
        <div class="pagination">
          <div class="pagination-sizes">
            <font>{{commonLangHandler('chuneng14_page','分页', getZEdata)}}</font>
            <span>1/10</span>
          </div>
          <div class="pagination-btngroup">
            <i class="lowcode icon-left"></i>
            <i class="lowcode icon-right"></i>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: right">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :page-size="page.pageSize"
          :current-page="page.currentPage"
          :total="page.total"
          @prev-click="preClick"
          @next-click="nextClick"
        >
        </el-pagination>
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <lvscBox18
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
  <script>
export default {
  name: "lvscBox18",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          this.getElHeight();
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      page: {
        pageSize: 10,
        total: 1,
        currentPage: 1,
      },
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      searchObj: {
        TYPE: "所有",
        ITEM:""
      },
      eqlist:[],
      tableHeight: 400,
      columns: [
        {
          title: this.commonLangHandler('lvscBox22_passage' , '通道', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          dataIndex: "name",
          key: "name",
          defaultSortOrder: "descend",
          width: 200,
        },
        {
          title: this.commonLangHandler('eventSwitch_status' , '状态', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          dataIndex: "status",
          key: "status",
          defaultSortOrder: "descend",
          sorter: true,
          width: 200,
        },
        {
          title: this.commonLangHandler('lvscBox22_numValue' , '数值', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          dataIndex: "totalApparentPower",
          key: "totalApparentPower",
          defaultSortOrder: "descend",
          width: 200,
        },
      ],
      detailInfo: {
        list: [],
      },
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
  },
  methods: {
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type: "电表",
        },
      }).then((res) => {
        //console.log(res);
        let arr = [];
        res.data.data.forEach((item) => {
          let obj = {
            value: item.assetNumber,
            label: item.assetName,
          };
          arr.push(obj);
          //console.log(arr)
        });
        this.eqlist = arr;
        //console.log(this.eqlist)
      });
    },
    preClick(i) {
      this.page.currentPage = i;
      this.getData();
    },
    nextClick(i) {
      this.page.currentPage = i;
      this.getData();
    },
    currentChange(i) {
      this.page.currentPage = i;
      this.getData();
    },
    collFn(row) {
      let url = "";
      let type = "";
      url = Config.dev_url + "/api-apps-v2/api/v2/battery/airfire/status";
      type = "1";

      row.type = type;
      this.$axios({
        url,
        method: "post",
        data: {
          ...row,
          userName: this.currentuserinfo.USERNAME,
        },
      }).then((res) => {
        this.$message.success("操作成功");
        this.getData();
      });
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    handleChange(value) {
      this.page.currentPage = 1;
      this.getData();
    },
    // 获取数据
    getData() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/battery/airfire/status",
        method: "post",
        data: {
          assetNumber:this.searchObj['ITEM']
          // deptCode: this.currentSelectDeptInfo.CODE,
          //   page: this.page.currentPage,
          //   size: this.page.pageSize
        },
      }).then((res) => {
        // this.page.total = res.data.data.count
        let result = res.data.data.valueList;
        this.isLoading = true;
        this.detailInfo = {};
        this.detailInfo.list = result;
        this.isLoading = false;
      });
    },
    getData0() {
      this.isLoading = true;
      this.detailInfo = {};
      var list = [
        { id: 1, word1: "1AT10_1", word2: "40", word3: "34", word4: "64" },
        { id: 2, word1: "1AT10_2", word2: "23", word3: "55", word4: "128" },
        { id: 3, word1: "1AT10_3", word2: "21", word3: "45", word4: "237" },
        { id: 4, word1: "1AT10_4", word2: "32", word3: "33", word4: "46" },
        { id: 5, word1: "1AT10_5", word2: "43", word3: "126", word4: "246" },
        { id: 6, word1: "1AT10_6", word2: "27", word3: "345", word4: "633" },
        { id: 7, word1: "1AT10_7", word2: "57", word3: "210", word4: "212" },
        { id: 8, word1: "1AT10_8", word2: "72", word3: "32", word4: "235" },
        { id: 9, word1: "1AT10_9", word2: "35", word3: "44", word4: "324" },
        { id: 10, word1: "1AT10_10", word2: "26", word3: "44", word4: "256" },
      ];
      this.detailInfo.list = list;
      this.isLoading = false;
    },
    getElHeight() {
      if (!!this.$el) {
        // this.tableHeight = this.$el.clientHeight - 180
        this.tableHeight = this.$el.clientHeight - 180;
      }
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getList()
      this.getData();
    }
  },
};
</script>
  
  <style lang="less" scoped>
.lvscBox18 {
  /deep/.ant-radio-group {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: #f5f5f5;
    .ant-radio-button-wrapper {
      //background: inherit;
      //margin: 3px;
      border: 0;
      height: 26px;
      line-height: 26px;
      &::before {
        background-color: transparent;
      }
    }
    .ant-radio-button-wrapper-checked {
      background: #3366ff !important;
      border-radius: 2px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

      font-size: 14px;
      font-family: ABBvoice_WCNSG_Rg;
      font-weight: 400;
      text-align: center;
      color: #fff;
      border: 0;
      &::before {
        background-color: transparent;
      }
    }
  }

  .list-content {
    position: relative;
    height: inherit;
  }

  /deep/.ant-table-thead {
    tr {
      th {
        background: #f5f5f5;
        padding: 9px 16px;
      }
    }
  }
  /deep/.ant-table-body {
    .ant-table-column-sort {
      background: #fff;
    }
    .ant-table-thead {
      tr {
        th {
          background: transparent;
        }
      }
    }
    .ant-table-tbody {
      td {
        padding: 8px 16px;
      }
    }
  }
  .icon-weishoucang {
    &:hover {
      color: #3366ff;
      cursor: pointer;
    }
  }
  .icon-shoucang {
    &:hover {
      color: red;
      cursor: pointer;
    }
    color: #3366ff;
  }
  .pagination {
    margin-top: 16px;
    height: 33px;
    line-height: 33px;
    display: flex;
    justify-content: flex-end;
    .pagination-sizes {
      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
      span {
        display: inline-block;
        line-height: 33px;
        border-bottom: 1px solid #bababa;
      }
    }
    .pagination-btngroup {
      margin-left: 41px;
      i {
        color: rgba(31, 31, 31, 0.16);
        &:nth-child(2) {
          margin-left: 16px;
          color: #1f1f1f;
        }
      }
    }
  }
}
</style>
  